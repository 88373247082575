import request from "@utils/request2";
import upload from "@utils/upload";

/**
 * 上传图片
 * @returns {*}
 */
export function uploadImg(data) {
    return upload.post("upload/image", data, { login: true });
}
/**
 * 获取地区
 * @returns {*}
 */
export function getRegion(data) {
    return request.get("mini/Region", data, { login: false });
}
/**
 * 获取菜单
 * @returns {*}
 */
export function getmenu(data) {
    return request.get("mini/menu", data, { login: false });
}
/**
 * 获取首页品牌轮播图
 * @returns {*}
 */
export function getBrand(data) {
    return request.get("mini/Brand", data, { login: false });
}
/**
 * 获取品牌中心品牌列表
 * @returns {*}
 */
export function getBrandList(data) {
    return request.get("mini/BrandList", data, { login: false });
}
/**
 * 品牌中心使用品牌id或商品列表
 * @returns {*}
 */
 export function getBrandProduct(data) {
    return request.get("mini/BrandProduct", data, { login: false });
}
/**
 * 获取品牌中心切换产品
 * @returns {*}
 */
export function switchProduce(data) {
    return request.get("mini/switchProduce", data, { login: false });
}
/**
 * 获取产品详情
 * @returns {*}
 */
export function ProduceComplex(data) {
    return request.get("mini/ProduceComplexs", data, { login: false });
}
/**
 * 获取产品酒庄信息
 * @returns {*}
 */
export function countries(data) {
    return request.get("mini/countries", data, { login: false });
}
/**
 * 获取店铺信息
 * @returns {*}
 */
export function businessData(data) {
    return request.post("mini/Store", data, { login: false });
}
/**
 * 获取产品详情视频
 * @returns {*}
 */
export function ProductVideo(data) {
    return request.get("mini/Product/Video", data, { login: false });
}
/**
 * 获取产品详情包装
 * @returns {*}
 */
 export function ProductPack(data) {
    return request.get("mini/Product/Pack", data, { login: false });
}
/**
 * 获取产品详情 广告图
 * @returns {*}
 */
 export function ProductAd(data) {
    return request.get("mini/Product/ad", data, { login: false });
}
/**
 * 获取品牌》产品筛选列表
 * @returns {*}
 */
export function EasyList(data) {
    return request.get("mini/Product/EasyList", data, { login: false });
}
/**
 * 获取单产品评分信息(主要用于杯酒发布产品信息)
 * @returns {*}
 */
export function productscore(data) {
    return request.get("mini/product/score", data, { login: false });
}
/**
 * 获取用酒场景列表
 * @returns {*}
 */
export function OccasionList(data) {
    return request.get("mini/Occasion/List", data, { login: false });
}
/**
 * 发布杯酒文章(提交)
 * @returns {*}
 */
export function Judgesave(data) {
    return request.post("mini/Judge/save", data, { login: true });
}
/**
 * 杯酒文章保存或更新 草稿
 * @returns {*}
 */
export function Draftsave(data) {
    return request.post("mini/JudgeDraft/save", data, { login: true });
}
/**
 * 获取草稿箱单条数据
 * @returns {*}
 */
export function Draftdata(data) {
    return request.post("mini/JudgeDraft/data", data, { login: true });
}
/**
 * 杯酒论人生 首页列表
 * @returns {*}
 */
export function JudgeListArray(data) {
    return request.get("mini/Judge/ListArray", data, { login: false });
}
/**
 * 杯酒论人生评论详情
 * @returns {*}
 */
export function JudgeDetails(data) {
    return request.get("mini/Judge/Details", data, { login: false });
}
/**
 * 杯酒论人生详情
 * @returns {*}
 */
export function miniProduce(data) {
    return request.get("mini/Produce", data, { login: false });
}
/**
 * 杯酒论人生详情
 * @returns {*}
 */
export function JudgeProductdetails(data) {
    return request.get("mini/JudgeProduct/details", data, { login: false });
}
/**
 * 杯酒论人生文章列表
 * @returns {*}
 */
export function JudgeList(data) {
    return request.get("mini/Judge/List", data, { login: false });
}
/**
 * 杯酒论人生文章点赞
 * @returns {*}
 */
export function JudgeZan(data) {
    return request.post("mini/Judge/Zan", data, { login: false });
}
/**
 * 杯酒论人生文章收藏
 * @returns {*}
 */
export function JudgeCollect(data) {
    return request.post("mini/Judge/Collect", data, { login: false });
}
/**
 * 杯酒论人生文章评论
 * @returns {*}
 */
export function Commentsave(data) {
    return request.post("mini/Comment/save", data, { login: false });
}
/**
 * 杯酒论人生文章评论列表
 * @returns {*}
 */
export function commentList(data) {
    return request.get("mini/comment/List", data, { login: false });
}
/**
 * 杯酒论人生文章评论回复列表
 * @returns {*}
 */
export function replyList(data) {
    return request.get("mini/reply/List", data, { login: false });
}
/**
 * 杯酒论人生文章评论回复点赞
 * @returns {*}
 */
export function CommentZan(data) {
    return request.post("mini/Judge/CommentZan", data, { login: false });
}
/**
 * 杯酒论人生文章评论回复评论
 * @returns {*}
 */
export function Replysave(data) {
    return request.post("mini/Reply/save", data, { login: false });
}
/**
 * 视频列表（视频栏目中的视频列表）
 * @returns {*}
 */
export function VideoList(data) {
    return request.get("mini/Video/List", data, { login: false });
}
/**
 * 商户动态列表（新）
 * @returns {*}
 */
export function DynamicList(data) {
    return request.get("mini/Dynamic/List", data, { login: false });
}
/**
 * 商户动态详情（新）
 * @returns {*}
 */
export function DynamicDetails(data) {
    return request.get("mini/Dynamic/Details", data, { login: false });
}
/**
 * 用户类型（线上线下）
 * @returns {*}
 */
export function miniSwitch(data) {
    return request.post("mini/swtich", data, { login: false });
}
/**
 * 个人中心
 * @returns {*}
 */
 export function getUser(data) {
    return request.post("mini/user", data, { login: true });
}
/**
 * 个人中心菜单
 * @returns {*}
 */
 export function getMenu(data) {
    return request.post("mini/menu", data, { login: true });
}

/**
 * 商家动态列表
 * @returns {*}
 */
 export function dynamicList(data) {
    return request.post('business/dt',data,{noAuth:false});
}
/**
 * 商家切换动态列表状态
 * @returns {*}
 */
 export function dtShow(data) {
    return request.post('business/dtShow',data,{noAuth:false});
}
/**
 * 动态发布  参数 title 标题，detailspage 详情内容，images 封面
*/
export function dtRelease(data){
    return request.post('business/dtRelease',data,{noAuth:false});
}

/**
 * 关注与粉丝列表 关注：1 粉丝：0
*/
export function getfollowList(data){
    return request.get('mini/followList',data,{noAuth:false});
}
/**
 * 设置关注  $to_uid 目标id
*/
export function setFollow(data){
    return request.get('mini/setFollow',data,{noAuth:true});
}

/**
 * 商家店铺资料
*/
export function getBusinessData(data){
    return request.get('business/businessData',data,{noAuth:false});
}

/**
 * 商家编辑店铺资料
*/
export function saveBusinessData(data){
    return request.post('businessSave/data',data,{noAuth:true});
}
/**
 * 访客列表 page 1  limit 10
*/
export function getVisitor(data){
    return request.get('mini/visitor',data,{noAuth:true});
}
/**
 * 店铺用户列表 page 1  limit 10
*/
export function getSpreadList(data){
    return request.post('business/spreadlist',data,{noAuth:true});
}
/**
 * 获取产品列表  是否显示招商价
*/
export function getUserShowPrice(data){
    return request.get('mini/getUserShowPrice',data,{noAuth:true});
}

/**
 * 设置招商价
*/
export function setUserShowPrice(data){
    return request.get('mini/setUserShowPrice',data,{noAuth:true});
}
/**
 * 条码列表 page 1  limit 10 keyword start_time end_time keyword_city		   
*/
export function getCodeList(data){
    return request.post('codelist/list',data,{noAuth:true});
}
/**
 * 扫码数据统计 page 1  limit 10 keyword start_time end_time keyword_city		   
*/
export function getCodeBind(data){
    return request.post('codebind/list',data,{noAuth:true});
}
/**
 * 获取品牌列表
*/
export function getpinpai(data){
    return request.get('business/getpinpai',data,{noAuth:false});
}
/**
 * 获取单品牌产品
*/
export function BrandProduce(data){
    return request.get('mini/BrandProduce',data,{noAuth:false});
}
/**
 * 我的品鉴列表
*/
export function JudgeUserList(data){
    return request.post('mini/judgeUserList',data,{noAuth:true});
}
/**
 * 我的品鉴列表 统计
*/
export function JudgeCount(data){
    return request.get('mini/JudgeCount',data,{noAuth:true});
}
/**
 * 我的品鉴 审核列表 统计
*/
export function JudgeStatusCount(data){
    return request.get('mini/JudgeStatusCount',data,{noAuth:true});
}
/**
 * 我的品鉴 审核列表 切换状态
*/
export function switchJudgeStatus(data){
    return request.post('mini/switchJudgeStatus',data,{noAuth:true});
}
/**
 * 我的品鉴 未通过列表 编辑
*/
export function editJudge(data){
    return request.get('mini/editJudge',data,{noAuth:true});
}
/**
 * 我的品鉴 审核列表 删除文章
*/
export function delJudge(data){
    return request.get('mini/delJudge',data,{noAuth:true});
}
/**
 * 统计页面详情
*/
export function chartData(data){
    return request.get('Store/chart/',data,{noAuth:true});
}
/**
 * 留言窗 历史留言列表
*/
export function message(data){
    return request.post('mini/message',data,{noAuth:true});
}
/**
 * 留言列表
*/
export function messageList(data){
    return request.post('mini/messageList',data,{noAuth:true});
}
/**
 * 留言列表(删除)
*/
export function delMessage(data){
    return request.get('mini/delMessage',data,{noAuth:true});
}
/**
 * 留言
*/
export function saveMessage(data){
    return request.post('mini/saveMessage',data,{noAuth:true});
}
/**
 * 获取销售点
*/
export function getSaleDianList(data){
    return request.get('mini/getSaleDianList',data,{noAuth:false});
}
/**
 * 坐标解析地址
*/
export function getLocationRegin(data){
    return request.get('mini/LocationRegin',data,{noAuth:false});
}
/**

 * 推荐 或 活动弹窗  //瓶码    
 * 输出： code 0：没有弹窗 1：精选推荐 2：活动 
 */
 export function getSelectedAndActivity(data){
    return request.get('mini/SelectedAndActivity',data,{noAuth:false});
}
/**

 * 活动页面参数
 */
 export function getActivity(data){
    return request.get('mini/getActivity',data,{noAuth:false});
}
/**
 * 铺货核销 //code 瓶码 number 核销码
 */
 export function getActivityCode(data){
    return request.get('mini/ActivityCode',data,{noAuth:false});
}
/**
 * 提现
 */
 export function getCashCode(data){
    return request.get('mini/cashCode',data,{noAuth:false});
}
/**
/**
 * 关闭弹窗
 */
 export function getPopupShow(data){
    return request.get('mini/setPopupShow',data,{noAuth:false});
}
/**
 * 判断活动类型 0:没有 1：换酒 2：换钱
 */
 export function getActivityType(data){
    return request.get('mini/ActivityType',data,{noAuth:false});
}
/**
 * 活动订单列表
 */
 export function getScanOrderList(data){
    return request.get('mini/ScanOrderList',data,{noAuth:true});
}
/**
 * 活动订单列表个数
 */
 export function getScanOrderListCount(data){
    return request.get('mini/ScanOrderCount',data,{noAuth:true});
}
/**
 * 活动订单(用户操作确认收货)
 */
 export function getStatusUser(data){
    return request.get('mini/StatusUser',data,{noAuth:true});
}
/**
 * 生成店铺
 */
 export function generateStore(data){
    return request.post('user/generateStore',data,{noAuth:true});
}
/**
 * 获取倍数列表
 */
 export function MultipleList(data){
    return request.get('mini/MultipleList',data,{noAuth:true});
}
/**
 * 设置倍数 id
 */
 export function setMultiple(data){
    return request.get('mini/setMultiple',data,{noAuth:true});
}
/**
 * 获取当前倍数
 */
 export function getMultiple(data){
    return request.get('mini/getMultiple',data,{noAuth:true});
}
/**
 * 获取海报弹窗数据
 */
 export function getPoster(data){
    return request.get('mini/Poster',data,{noAuth:false});
}
/**
 * 获取讲解视频
 */
 export function getExpVideo(data){
    return request.get('mini/expVideo',data,{noAuth:false});
}
/**
 * 扫码根据code检索product_id
 * @returns {*}
 */
 export function goWcjProduct(data) {
    return request.get("goWcjProduct", data, { login: false });
  }
/**
 * 获取Urlscheme
 * @returns {*}
 */
 export function getUrlscheme(data) {
    return request.post("getUrlscheme", data, { login: false });
  }
  /**
 * chk
 * @returns {*}
 */
 export function getchk(data) {
    return request.get("chk", data, { login: false });
  }
  /**
 * 获取Urlscheme
 * @returns {*}
 */
 export function postaddress(data) {
    return request.post("postaddress", data, { login: false });
  } 
// /**
//  * 招商页面信息
//  * @param {*} data 
//  * @returns 
//  */
// export function canvassInfo(data) {
//     return request.get("canvass/info", data, { login: false });
// }
// /**
//  * 提交招商信息
//  * @param {*} data 
//  * @returns 
//  */
// export function submitCanvassBusiness(data) {
//     return request.post("canvass/submit", data, { login: false });
// }

// /**
//  * 招商协议
//  * @param {*} data 
//  * @returns 
//  */
//  export function getAgreement(data) {
//     return request.get("getAgreement", data, { login: false });
// }